import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import PreviewCompatibleImage from '../components/Blog/PreviewCompatibleImage';

const TagRoute = (props) =>  {
    const posts = props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((item, index) => {
      const post = item.node;
      return (
        <div className={index === posts.length - 1 ? "col-lg-4 col-md-6 offset-lg-0 offset-md-3" : "col-lg-4 col-md-6"} key={post.id}>
          <article className="single-blog-post">
            {post?.frontmatter?.featuredimage && (
              <Link to={post.fields.slug}>
                <div className="post-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                </div>
              </Link>
            )}
            <div className="post-content">
              <ul className="post-meta d-flex justify-content-between align-items-center">
                <li>
                  <i className="flaticon-calendar"></i>
                  <span className="ml-2">{post.frontmatter.date}</span>
                </li>
              </ul>
              <h3>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </h3>
              <p className="text-left pt-3 text-3">
                <span className="description">{post.frontmatter.description.trim() || post.excerpt}</span>
                <br />
                <Link className="button" to={post.fields.slug}>
                  Keep Reading <i class='flaticon-right-1'></i>
                </Link>
              </p>
            </div>
          </article>
        </div>
      )
    });

    const { tag } = props.pageContext;
    const { totalCount } = props.data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`;

    return (
      <Layout>
        <Navbar />
        <section className="section tag-section">
          <Helmet title={`Tag | ${tag}`} />
          <div className="container content">
            <div className="columns">
              <Link className="button" to="/tags/" component="a">
                ← Back to all tags
              </Link>
              <div
                className="column is-10 is-offset-1 mt-3"
                style={{ marginBottom: "6rem" }}
              >
                <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
                <div className="row">{postLinks}</div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    );
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                )
              }
            }
            description
          }
        }
      }
    }
  }
`;
